import * as React from "react"

//import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <>
    <Seo title="Contact" />
    <div className="container">
      <h1>Contact LiberSystems</h1>
      <div>
        <table className="table">
          <tbody>
            <tr>
              <td>Name</td>
              <td>Mat</td>
            </tr>
            <tr>
              <td>Residence</td>
              <td>Amersfoort, The Netherlands</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>
                <a href="mailto:matteljay@pm.me">matteljay@pm.me</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <hr className="invisHR" /> */}
      <p>If you have a project, feel free to make a business proposal.</p>
    </div>
  </>
)

export default ContactPage
